import axios from 'axios'
import Element from 'element-ui'
import router from './router'
import store from './store'


axios.defaults.baseURL = "https://www.mzy1203.com/api"
// axios.defaults.baseURL = "http://localhost:30071"
axios.defaults.headers.common.Authorization = `${localStorage.getItem("token")}`
// 前置拦截
axios.interceptors.request.use(config => {
  return config
})

axios.interceptors.response.use(response => {
    let res = response.data;

    console.log("=================")
    // console.log(res)
    console.log("=================")
    
    if (res!=null) {
      return response
    } else {

      Element.Message.error('错了哦，这是一条错误消息', {duration: 3 * 1000})

      return Promise.reject(response.data.msg)
    }
  },
  error => {
    error.message = error.response.data
    console.log(error.message)
    // Element.Message.warning(error.message, {duration: 3 * 1000})
    return Promise.reject(error)
  }
)