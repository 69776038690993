<template>
  <div class="bgBackground" :style="allbackground">
    <vue-particles color="#dedede" class="vueparticles">
    </vue-particles> 
    <blog-header></blog-header>
      <div  class="wrapper fn__flex header__meta">
      <div class="main">
        <div class="block">

          <div class="module ft__center module--bottom module--space">
          <h2 class="module__logo">{{this.labelName}}</h2>
        </div>

      <el-timeline >

        <el-timeline-item :label="true" :timestamp="article.createTime" :key='article.articleId' placement="top" v-for="article in articles">
          <el-card class="card">
            <h4>
              <router-link :to="{name: 'BlogsDetail', params: {articleId: article.articleId}}">
                {{article.articleTitle}}
              </router-link>
            </h4>
            <p>{{article.articleDescription}}</p>
          </el-card>
        </el-timeline-item>


      </el-timeline>

    </div>
      </div>

      <aside class="side" >
    <music-player></music-player>
    <blog-label style="position:relative"></blog-label>
    <article-star style="position:relative"></article-star>
    </aside>
      </div>

      <blog-footer>
      </blog-footer>
    

    
  </div>
</template>

<script>
import BlogHeader from '@/components/BlogHeader.vue'
import MusicPlayer from '@/components/MusicPlayer.vue'
import BlogFooter from '@/components/BlogFooter.vue'
import BlogLabel from '@/components/BlogLabel.vue'
import ArticleStar from '@/components/ArticleStar.vue'
export default {
    name:"DiffBlog",
    components:{BlogHeader,MusicPlayer, BlogFooter, BlogLabel, ArticleStar},
    data() {
      return {
        articles: {},
        allbackground:{
          backgroundImage:'url('+ require('../assets/background1.png') + ')',
          backgroundRepeat:'no-repeat',
          backgroundSize:'cover',
          backgroundPosition:'center top'
        },
        labelId:'',
        labelName:"",
        value: new Date(),
      }
    },
    created(){
      const _this=this
      _this.labelId = this.$route.params.labelId;
      this.getArticleByLabel_id()

    },
    methods: {
      getArticleByLabel_id(){
        let label_id=this.labelId
        this.$axios.get('/label/ownLabel?labelId='+label_id)
        .then(res => {
          // console.log(res)
          this.articles=res.data.data
          this.labelName=this.articles[0].labelName
          // console.log(this.labelName+"!!!!!!!!!!!!!!!!!!!!!!!!!!!")
        })
        .catch(err => {
          console.error(err); 
        })
      },
    },
}
</script>

<style scoped>

.wrapper {
    max-width: 1203px;
    padding: 0 10px;
    margin: 0 auto
}
.fn__flex {
    display: flex
}
.main {
    flex: 1 1 66.6%;
    max-width: 66.6%;
    flex-shrink: 0
}
.module__logo {
    animation: logo-rotate 5s infinite;
}

.module--bottom {
    margin-bottom: 12px;
}

.module--space {
    padding: 6px 12px;
}

.ft__fade {
    color: rgba(0,0,0,.38);
}
.ft__13 {
    font-size: 13px;
}

.side {
    flex: 1 1 33.4%;
    margin-left: 24px;
    margin-top: 18px;
}

.header__meta {
    margin-top: 70px
}
.bgBackground{
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  top: 0;
  left: 0;
  z-index:-2;
}

 #particles-js{
        width: 100%;
        height: calc(100% - 100px);
        position: fixed;   /* 设置absolute,其他DIV设置为relative,这样这个例子效果就作为背景 */
        
    }
.vueparticles{
  width: 100%;
  height: 100%;
  background-attachment: fixed;
}

</style>