<template>
<div class="bgBackground" :style="allbackground">
    <vue-particles color="#dedede" class="vueparticles">
    </vue-particles> 
    <blog-header>
    </blog-header>
    <div  class="mblog fn__flex header__meta">
        <div class="main">
            <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                type="selection"
                width="55">
                </el-table-column>
                <el-table-column
                prop="parentId"
                label="顶级ID"
                width="70">
                </el-table-column>
                <el-table-column
                prop="replyId"
                label="回复ID"
                width="70">
                </el-table-column>
                <el-table-column
                prop="replyUsername"
                label="回复人姓名"
                width="90">
                </el-table-column>
                <el-table-column
                prop="message"
                label="回复信息"
                width="280">
                </el-table-column>
                <el-table-column
                prop="username"
                label="发送人姓名"
                width="100">
                </el-table-column>
        
            </el-table>
            <div style="position: relative;margin-top: 20px" >
                <el-button @click="pass()">审核通过</el-button>
                <el-button @click="nopass()">审核不通过</el-button>
            </div>

        </div>

    <aside class="side">
      <music-player>
      </music-player>
    </aside>
    </div>
    
    <blog-footer>
  </blog-footer>
</div>
</template>

<script>
import BlogHeader from '../components/BlogHeader.vue'
import BlogFooter from '../components/BlogFooter.vue'
import MusicPlayer from '../components/MusicPlayer.vue'
export default{
    components: { BlogHeader, BlogFooter,MusicPlayer},
    name:"CommentModeration",
    inject:['reload'],
    data(){
        return{
        allbackground:{
          backgroundImage:'url('+ require('../assets/background1.png') + ')',
          backgroundRepeat:'no-repeat',
          backgroundSize:'cover',
          backgroundPosition:'center top'
        },
        tableData: [{
          parentId: 0,
          replyId: 0,
          replyUsername: 'mzy',
          message: "测试数据",
          username: "测试数据"
        }],
        multipleSelection: []
        }

    },
    created(){
            
        this.getTheMessage()
        
    },
    methods: {
        handleSelectionChange(val) {
        this.multipleSelection =  val;
      },
      getTheMessage(){
        const _this = this
        this.$axios.get('/message/getAllUncheckMessage').
        then(res =>{
            _this.tableData = res.data.data
        }).catch(err =>{
            console.log(err)
        })
      },
      pass(){
        console.log(this.multipleSelection)
        this.$axios.post('/message/passCheck',this.multipleSelection).
        then(res =>{
            this.$message({
                   showClose: true,
                   type: "success",
                   message: res.data.data,
                  });
            this.reload()
        }).catch(err =>{
            console.log(err)
        })
      },
      nopass(){
        this.$axios.post('/message/failedInspection',this.multipleSelection).
        then(res =>{
            this.$message({
                   showClose: true,
                   type: "success",
                   message: res.data.data,
                  });
            this.reload()
        }).catch(err =>{
            console.log(err)
        })
      }
    }
    

}

</script>

<style scoped>
.bgBackground{
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  top: 0;
  left: 0;
  z-index:-2;
}
.mblog {
    max-width: 1203px;
    padding: 0 10px;
    margin: 0 auto

  }
.side {
    flex: 1 1 33.4%;
    margin-top: 70px;
    margin-left: 24px
}
.wrapper {
    max-width: 1203px;
    padding: 0 10px;
    margin: 0 auto
}
.fn__flex {
    display: flex
}
.header__meta {
    margin-top: 70px
}
.main {
    flex: 1 1 66.6%;
    max-width: 66.6%;
    flex-shrink: 0;
    margin-top: 70px;
    margin-bottom: 70px;
}
#particles-js {
  width: 100%;
  height: calc(100% - 100px);
  position: fixed; /* 设置absolute,其他DIV设置为relative,这样这个例子效果就作为背景 */
}
.vueparticles{
  width: 100%;
  height: 100%;
  background-attachment: fixed;
}
</style>