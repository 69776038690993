<template>
  <div>
  
    <div id="aplayer"></div>
   
  
  </div>
</template>

<script>
import APlayer from 'aplayer'
import "aplayer/dist/APlayer.min.css"
export default {
    name:"MusicPlayer",
    data() {
    return {
      audio: [ // 歌曲列表
        {
          name: "歩いても 歩いても", // 歌曲名字
          artist: "ゴンチチ", // 歌曲演唱者
          url: // 歌曲地址（这里用外链地址）
            "https://www.mzy1203.com/download/music/ゴンチチ - 歩いても 歩いても.mp3",
          cover: "https://www.mzy1203.com/download/music/ゴンチチ - 歩いても 歩いても.jpeg", // 歌曲头像
          lrc: "", // 歌词
          theme: "rgb(127, 218, 180)", // 播放这首歌曲时的主题色
        },
        {
          name: "Love Theme",
          artist: "小瀬村晶",
          url:
            "https://www.mzy1203.com/download/music/小瀬村晶 - Love Theme.mp3",
          cover: "https://www.mzy1203.com/download/music/小瀬村晶 - Love Theme.jpeg",
          lrc: "",
          theme: "rgb(61, 162, 230)",
        },
        {
          name: "Merry Christmas Mr. Lawrence",
          artist: "坂本龍一",
          url:
            "https://www.mzy1203.com/download/music/坂本龍一 - メリー・クリスマス・Mr.ローレンス.mp3",
          cover: "https://www.mzy1203.com/download/music/坂本龍一 - Merry Christmas Mr. Lawrence.jpeg",
          lrc: "",
          theme: "#baf",
        },
      ],
      info: {
        fixed: false, // 不开启吸底模式
        listFolded: false, // 不折叠歌曲列表
        autoplay: true, // 开启自动播放
        preload: "auto", // 自动预加载歌曲
        listMaxHeight: 90,
        loop: "all", // 播放循环模式、all全部循环 one单曲循环 none只播放一次
        order: "list", //  播放模式，list列表播放, random随机播放
      },
    }
  },
  mounted() {
    // 初始化播放器
    this.initAudio();
  },
  methods: {
    initAudio() {
      // 创建一个音乐播放器实例，并挂载到DOM上，同时进行相关配置
      const ap = new APlayer({ // eslint-disable-line no-unused-vars
        container: document.getElementById("aplayer"),
        audio: this.audio, // 音乐信息
        ...this.info, // 其他配置信息
      });
    },
  },

}
</script>

<style scoped>
.aplayer{
  text-align: left;
}

</style>