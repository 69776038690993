<template>
  <div>
    <div class="module module--bottom">
<div class="module__header fn__flex-center">
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-stars" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z"/>
  <path d="M2.242 2.194a.27.27 0 0 1 .516 0l.162.53c.035.115.14.194.258.194h.551c.259 0 .37.333.164.493l-.468.363a.277.277 0 0 0-.094.3l.173.569c.078.256-.213.462-.423.3l-.417-.324a.267.267 0 0 0-.328 0l-.417.323c-.21.163-.5-.043-.423-.299l.173-.57a.277.277 0 0 0-.094-.299l-.468-.363c-.206-.16-.095-.493.164-.493h.55a.271.271 0 0 0 .259-.194l.162-.53zm0 4a.27.27 0 0 1 .516 0l.162.53c.035.115.14.194.258.194h.551c.259 0 .37.333.164.493l-.468.363a.277.277 0 0 0-.094.3l.173.569c.078.255-.213.462-.423.3l-.417-.324a.267.267 0 0 0-.328 0l-.417.323c-.21.163-.5-.043-.423-.299l.173-.57a.277.277 0 0 0-.094-.299l-.468-.363c-.206-.16-.095-.493.164-.493h.55a.271.271 0 0 0 .259-.194l.162-.53zm0 4a.27.27 0 0 1 .516 0l.162.53c.035.115.14.194.258.194h.551c.259 0 .37.333.164.493l-.468.363a.277.277 0 0 0-.094.3l.173.569c.078.255-.213.462-.423.3l-.417-.324a.267.267 0 0 0-.328 0l-.417.323c-.21.163-.5-.043-.423-.299l.173-.57a.277.277 0 0 0-.094-.299l-.468-.363c-.206-.16-.095-.493.164-.493h.55a.271.271 0 0 0 .259-.194l.162-.53z"/>
</svg>
点赞排行
</div>
<ul class="module__list module--space">

<li class="fn__flex-center" :key="article.id" v-for="article in articles">
<router-link   :to="{name: 'BlogsDetail', params: {articleId: article.id}}">
                {{article.title}}
</router-link>
</li>

</ul>
</div>
  </div>
</template>

<script>

export default {
    name:"ArticleStar",
    data(){
        return{
            articles:{}
        }
    },
    created () {
        this.getTenBefor()
    },
    methods: {
        getTenBefor(){
            this.$axios.get('/article/order')
            .then(res => {
                // console.log(res)
                console.log("+++++++++++++")
                this.articles=JSON.parse(JSON.stringify(res.data.data))
            })
            .catch(err => {
                console.error(err); 
            })
        }
    },
}
</script>

<style scoped>
.module {
    background-color: rgba(255, 255, 255, .6);
    box-sizing: border-box;
    white-space: normal;
    word-break: break-all
}
.module--space {
    padding: 6px 12px
}
.module--bottom {
    /* margin-bottom: 12px; */
    margin: 5px;
}

.module__header {
    box-sizing: border-box;
    color: #48494d;
    border-bottom: 1px dashed #eee;
    padding: 12px;
    width: 100%;
    line-height: 14px
}
.module__tags a {
    line-height: 26px;
    padding: 0 6px;
    margin: 6px 12px 6px 0;
    background-color: rgba(196, 196, 196, .4);
    display: inline-block
}
.fn__flex-center {
    display: inline-flex;
    align-items: center
}
.fn__clear:before, .fn__clear:after {
    display: table;
    content: ""
}
.module__list {
    list-style: none
}

.module__list li {
    width: 100%;
    box-sizing: border-box;
    padding: 6px 12px;
    line-height: 24px;
    transition: all .25s linear
}

.module__list li:hover {
    background-color: rgba(196, 196, 196, .4)
}

.module__list li a {
    font-weight: 600
}

.module__list li .avatar {
    margin-right: 6px
}


</style>