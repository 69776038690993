<template>
  <div>
    <div class="module module--bottom">
<div class="module__header fn__flex-center">
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tags" viewBox="0 0 16 16">
  <path d="M3 2v4.586l7 7L14.586 9l-7-7H3zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2z"/>
  <path d="M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1v5.086z"/>
</svg>
标签
</div>
<div class="module__tags module--space fn__clear">




<a href="javascript:void(0);" :key='label.labelId' placement="top" v-for="label in distinctLabel">
    <router-link :to="{name: 'DiffBlog', params: {labelId: label.labelId}}">
                {{label.labelName}}
    </router-link>
</a>

</div>
</div>
  </div>
</template>

<script>
export default {
    name:"BlogLabelo",
    data () {
        return {
            distinctLabel:[],
        }
    },
    created () {
        this.getAlldistinctLabel()
    },
    methods: {
      getAlldistinctLabel(){
          this.$axios.get('/label')
          .then(res => {
            //   console.log(res)
              this.distinctLabel=res.data.data
              this.distinctLabel=JSON.parse(JSON.stringify(this.distinctLabel))
          })
          .catch(err => {
              console.error(err); 
          })
      }
    }
}
</script>

<style scoped>
.module {
    background-color: rgba(255, 255, 255, .6);
    box-sizing: border-box;
    white-space: normal;
    word-break: break-all
}
.module--space {
    padding: 6px 12px
}
.module--bottom {
    /* margin-bottom: 12px; */
    margin: 5px;
}

.module__header {
    box-sizing: border-box;
    color: #48494d;
    border-bottom: 1px dashed #eee;
    padding: 12px;
    width: 100%;
    line-height: 14px
}
.module__tags a {
    line-height: 26px;
    padding: 0 6px;
    margin: 6px 12px 6px 0;
    background-color: rgba(196, 196, 196, .4);
    display: inline-block
}
.fn__flex-center {
    display: inline-flex;
    align-items: center
}
.fn__clear:before, .fn__clear:after {
    display: table;
    content: ""
}
</style>