import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginAndRegister from '../views/LoginAndRegister.vue'
import BlogsView from '../views/BlogsView.vue'
import BlogsDetail from '../views/BlogsDetail.vue'
import BlogEdit from '../views/BlogEdit.vue'
import PersonMessage  from '../views/PersonMessage.vue'
import DiffBlog from '../views/DiffBlog.vue'
import UserRegister from '../views/UserRegister.vue'
import CommentModeration from '../views/CommentModeration.vue'
import UserManagement from '../views/UserManagement.vue'
Vue.use(VueRouter)

 
const routes = [
  {
    path: '/',
    name: 'Index',
    redirect: {name:"BlogsView"}
  },
  {
    path: '/blogs',
    name: 'BlogsView',
    component: BlogsView
  },
  {
    path: '/loginandregister',
    name: 'LoginAndRegister',
    component: LoginAndRegister
  },
  
  {
    path: '/blogedit/add',
    name: 'BlogEdit',
    component: BlogEdit,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/blogsdetail/:articleId',
    name: 'BlogsDetail',
    component: BlogsDetail
  },
  {
    path: '/blogedit/:articleId/edit',
    name: 'BlogEdit',
    component: BlogEdit,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/message',
    name: 'PersonMessage',
    component: PersonMessage
  },
  {
    path: '/diffblog/:labelId',
    name: 'DiffBlog',
    component: DiffBlog,
  },
  {
    path: '/register',
    name: 'UserRegister',
    component: UserRegister,
  },
  {
    path:'/commentModeration',
    name:'CommentModeration',
    component: CommentModeration
  },
  {
    path:'/userManagement',
    name:'UserManagement',
    component: UserManagement
  },

]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 解决控制台报重复路径警告
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router
