<template>
  <div id="app" >
    <router-view 
     :key="$route.fullPath"
     
     />
  </div>
</template>

<script>
export default {
  name:'App',
  provide(){
    return{
      reload:this.reload
      }
	},
  data(){
    return{
      isShow:true
    }
  },
  method:{
		reload(){
			this.isShow = false
			this.$nextTick(function(){
				this.isShow = true
})
}
},
mounted () {
    // 检测浏览器路由改变页面不刷新问题,hash模式的工作原理是hashchange事件
    window.addEventListener('hashchange', () => {
      console.log(window.location.hash,'window.location.hash')
      let currentPath = window.location.hash.slice(1)
      console.log(currentPath,'currentPath')
      if (this.$route.path !== currentPath) {
        this.$router.push(currentPath)
      }
    }, false)
  },

}
</script>

<style>
#app {
  /* text-align: center; */
  color: #2c3e50;
  max-height: 960px;
  margin: 0 auto;
}

</style>
