<template>
  <div  class="bgBackground" :style="allbackground">
    <vue-particles color="#dedede" class="vueparticles">
    </vue-particles> 
        <blog-header>
        </blog-header>
      
    <div class="header_meta wrapper fn__flex">

        
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
         <el-form-item label="用户名" prop="username" style="font-size:24px">
            <el-input v-model="ruleForm.username"></el-input>
          </el-form-item>
         <el-form-item label="密码" prop="pass">
         <el-input type="password" v-model="ruleForm.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
         <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="姓名" prop="name" style="font-size:24px">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="身份证号码" prop="idNo" style="font-size:24px">
            <el-input v-model="ruleForm.idNo"></el-input>
          </el-form-item> -->
          <el-form-item label="邮箱" prop="email" style="font-size:24px">
            <el-input v-model="ruleForm.email"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="phone" style="font-size:24px">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="QQ" prop="qq" style="font-size:24px">
            <el-input v-model="ruleForm.qq"></el-input>
          </el-form-item>
          <el-form-item label="微信" prop="wechat" style="font-size:24px">
            <el-input v-model="ruleForm.wechat"></el-input>
          </el-form-item>
  <el-form-item>
    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
    <el-button @click="resetForm('ruleForm')">重置</el-button>
  </el-form-item>
</el-form>
</div>
     


    <blog-footer class="foot">
    </blog-footer>

  </div>
</template>

<script>
import BlogHeader from '@/components/BlogHeader.vue';
import BlogFooter from '@/components/BlogFooter.vue';
export default {
    components: { BlogHeader, BlogFooter },
    name:"UserRegister",
    data () {
        let validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
          }
          callback();
        }
      };
      let validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      let emailValidity = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入邮箱'));
        } else if (!/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(value)) {
          callback(new Error('邮箱格式错误!'));
        } else {
          callback();
        }
      };

       const idCardValidity = (rule, code, callback) => {
         var city = {
		11: "北京",
		12: "天津",
		13: "河北",
		14: "山西",
		15: "内蒙古",
		21: "辽宁",
		22: "吉林",
		23: "黑龙江 ",
		31: "上海",
		32: "江苏",
		33: "浙江",
		34: "安徽",
		35: "福建",
		36: "江西",
		37: "山东",
		41: "河南",
		42: "湖北 ",
		43: "湖南",
		44: "广东",
		45: "广西",
		46: "海南",
		50: "重庆",
		51: "四川",
		52: "贵州",
		53: "云南",
		54: "西藏 ",
		61: "陕西",
		62: "甘肃",
		63: "青海",
		64: "宁夏",
		65: "新疆",
		71: "台湾",
		81: "香港",
		82: "澳门",
		91: "国外 ",
	};
	var tip = "";
	var pass = true;
    if(!code) {
        pass = true;
    }else if (!/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)) {
		tip = "身份证号格式错误";
		pass = false;
	} else if (!city[code.substr(0, 2)]) {
		tip = "地址编码错误";
		pass = false;
	} else {
		// 18位身份证需要验证最后一位校验位
		if (code.length === 18) {
			code = code.split(""); // ∑(ai×Wi)(mod 11) // 加权因子
			var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 校验位
			var parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
			var sum = 0;
			var ai = 0;
			var wi = 0;
			for (var i = 0; i < 17; i++) {
				ai = code[i];
				wi = factor[i];
				sum += ai * wi;
			}
			var last = parity[sum % 11];
			if (parity[sum % 11] != code[17]) {
				tip = "校验位错误";
				pass = false;
			}
		}
	}
	if (!pass) {
		callback(new Error(tip));
	} else {
		callback();
	} // if (!pass) alert(tip) // return pass
};

        return {
        allbackground:{
          backgroundImage:'url('+ require('../assets/background1.png') + ')',
          backgroundRepeat:'no-repeat',
          backgroundSize:'cover',
          backgroundPosition:'center top'
        },
        
        ruleForm: {
          password: '',
          checkPass: '',
          username:'',
          // name:'',
          // idNo:'',
          phone:'',
          wechat:'',
          qq:'',
          email:''
        },
        rules: {
          pass: [
            //  {required: true, message: '请输入密码', trigger: 'blur' },
            { min: 6, max: 15, message: '长度在 6 到 15 个字符', triger: 'blur' },
            { validator: validatePass, trigger: 'blur' }

          ],
          checkPass: [
            //  {required: true, message: '请重新输入密码', trigger: 'blur' },
            { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' },
            { validator: validatePass2, trigger: 'blur' }

          ],
          username: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
            { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
          ],
          name: [
            { required: true, message: '请输入真实姓名', trigger: 'blur' },
          ],
          idNo: [
            { validator: idCardValidity, trigger: "blur" },
            { required: true, message: '请输入身份证', trigger: 'blur' },
          ],
          email:[
            {validator: emailValidity, trigger: "blur" },
            {required: true,message: '请输入邮箱',trigger: 'blur'}
            // {type: 'string',message: '邮箱格式不正确',trigger: 'blur',transform (value) {if (!/^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(value)) {return true}else{}}},
            // { type: 'string', message: '长度不能超过30位', trigger: 'blur', max: 30 },
            
          ],
          qq:[
          {required: false,message: 'qq',trigger: 'blur'}
          ],
          wechat:[
          {required: false,message: 'qq',trigger: 'blur'}
          ],
          phone:[
          {required: false,message: 'qq',trigger: 'blur'}
          ]

        }
      };
        
        },
    methods: {
        
        submitForm(formName) {
            
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const _this = this
            this.$axios.post('/user/save',_this.ruleForm)
            .then(res => {
                // console.log(res)
                if(res.data.status==200){
                  this.$message({
                   showClose: true,
                   type: "warning",
                   message: res.data.data,
                  });
                  _this.$alert("创建成功", "提示", {
                  confirmButtonText: "确定",
                  callback: (action) => {
                    _this.$router.push("/loginandregister");
                  },
                });
                }else{
                  this.$message({
                   showClose: true,
                   type: "warning",
                   message: res.data.data,
                  });
                }
             
            })
            .catch(err => {
                console.error(err); 
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }





}
</script>    


<style scoped >
.header__meta {
    margin-top: 70px
}
.wrapper {
    max-width: 1203px;
    padding: 0 10px;
    margin: 0 auto;
    min-height: calc(100vh - 56px)
}
.fn__flex {
    display: flex
}
.foot{
  margin-bottom: 0px;
  position: relative;;
}
  .demo-ruleForm {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 200px;
  }
  .bgBackground{
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  top: 0;
  left: 0;
  z-index:-2;
}
   #particles-js{
        width: 100%;
        height: calc(100% - 100px);
        position: fixed;   /* 设置absolute,其他DIV设置为relative,这样这个例子效果就作为背景 */
        
    }
  .vueparticles{
    width: 100%;
    height: 100%;
   background-attachment: fixed;
  }
</style>