<template>
  <div class="editblog" :style="allbackground">
    <vue-particles color="#dedede" class="vueparticles"> </vue-particles>

    <blog-header></blog-header>

    <el-main :style="allbackground">
      <div class="m-content">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="标题" prop="title">
            <el-input v-model="ruleForm.title"></el-input>
          </el-form-item>

          <el-form-item label="摘要" prop="description">
            <el-input
              type="textarea"
              v-model="ruleForm.description"
            ></el-input>
          </el-form-item>

          <el-form-item label="内容" prop="content">
            <mavon-editor v-model="ruleForm.content"></mavon-editor>
          </el-form-item>

          
          <el-tag
          :key="i"
          v-for="(label,i) in dynamicTags"
          closable
          :disable-transitions="false"
          @close="handleClose(label)"
        >
          {{ label.labelName }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput"
          >+ New Tag</el-button>

          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >立即创建</el-button
            >
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>

        

      </div>
    </el-main>
    <blog-footer> </blog-footer>
  </div>
</template>

<script>
import BlogHeader from "../components/BlogHeader.vue";
import BlogFooter from "../components/BlogFooter.vue";
export default {
  name: "BlogEdit",
  components: { BlogHeader, BlogFooter },
  data() {
    return {
      dynamicTags: [],
      allTages:[],
      username:'',
      user_id:'',
      inputVisible: false,
      inputValue: "",
      alreadyExists:false,
      nextArticleId:'',
      ruleForm: {
        id: '',
        title: "",
        description: "",
        content: "",
        createdBy:"",
      },
      allbackground: {
        backgroundImage: "url(" + require("../assets/background1.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center top",
      },
      rules: {
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          {
            min: 3,
            max: 25,
            message: "长度在 3 到 25 个字符",
            trigger: "blur",
          },
        ],
        description: [
          { required: true, message: "请输入摘要", trigger: "blur" },
        ],
        content: [
          { trequired: true, message: "请输入内容", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    const articleId = this.$route.params.articleId;
    // console.log(articleId+"??????????");
    const _this = this;
    if (articleId) {
      this.$axios.get("/article/" + articleId).then((res) => {
        const article = res.data.data;
        // console.log(article+"@@@@@@@@@@@@@@@")
        _this.ruleForm.id = article.id;
        _this.ruleForm.title = article.title;
        _this.ruleForm.description = article.description;
        _this.ruleForm.content = article.content;
        _this.ruleForm.createdBy = article.createdBy
        // console.log(_this.ruleForm.article_id+"!!!!!!!!!!!!!!!!!!!!!!!!!!!1")
      });
    }
    this.getAllLabel()
    this.getUserinfo()
  },
  methods: {
    // 获取登录用户信息
    getUserinfo(){
            const _this=this
            let user=localStorage.getItem("userInfo");// 注意不能获取store中的值，要放到localStorage或者会话储存中
            if(user){
                let userInfo=JSON.parse(user)
                _this.username=userInfo.username;
                _this.user_id=userInfo.id;
                // console.log(_this.user_id+"000000000000000000")
            }

        },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this;
          let id = this.$route.params.articleId
          // console.log(id+" SSSSSSSSSSSSSSSS")
          if (id != null) {
            this.$axios
              .put(`/article`, this.ruleForm, {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              })
              .then((res) => {
                // console.log(res);
                _this.$alert("修改成功", "提示", {
                  confirmButtonText: "确定",
                  callback: (action) => {
                    _this.$router.push("/blogs");
                  },
                });
              })
              .catch((err) => {
                console.error(err);
              });
            // 更新文章标签
            this.$axios.post(`/label?id=${id}`,this.dynamicTags
            )
          .then(res => {
            // console.log(res)
            console.log("成功更新标签")
          })
          .catch(err => {
            console.error(err); 
          })

          } else {
            this.ruleForm.createdBy = this.username
            this.$axios.post("/article/create", this.ruleForm, {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              })
              .then((res) => {
                // console.log(res.data.data);
                let aid = res.data.data
                // console.log(this.nextArticleId);
                if(res.data.status == 200){
                // 保存文章标签
                for(var i=0;i<this.dynamicTags.length;i++){
                    this.dynamicTags[i].articleId=this.nextArticleId
                }
                this.$axios.post(`/label?id=${aid}`,this.dynamicTags)
                .then(res => {
                  this.$message({
                   showClose: true,
                   type: "success",
                   message: res.data.data,
                  });
                })
                .catch(err => {
                  console.error(err); 
                })
                 _this.$alert("文章保存成功", "提示", {
                  confirmButtonText: "确定",
                  callback: (action) => {
                    _this.$router.push("/blogs");
                  },
                });
                }
              }).catch(err => {
                 console.error(err); 
              })
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleClose(label) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(label), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    getAllLabel(){
      this.$axios.get('/label')
      .then(res => {
        // console.log(res)
        this.allTages=res.data.data
        // console.log(this.allTages)
        this.allTages=JSON.parse(JSON.stringify(this.allTages))
        // console.log(this.allTages)
        if(this.$route.params.articleId){
          for(var i=0;i<this.allTages.length;i++){
            this.allTages[i].articleId=this.$route.params.articleId
          }
        }
        this.dynamicTags=this.allTages
        console.log(this.dynamicTags[0].articleId)
        // this.nextArticleId=this.dynamicTags[0].articleId
        // console.log("PPPPPPPPPPPPPPPPPPPP")
        // console.log(this.allTages)
        // console.log("PPPPPPPPPPPPPPPPPPPP")
      })
      .catch(err => {
        console.error(err); 
      })


    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      console.log(this.allTages)
      console.log(this.dynamicTags)
      let did=this.allTages.findIndex(label => {
       return label.labelName == inputValue;})
      if(did==-1){
        let label={}
        label.labelId=-1
        label.labelName=inputValue
        if(this.$route.params.articleId!=null){
          label.articleId=this.$route.params.articleId 
        }
        else{
          label.articleId=this.nextArticleId
        }
        
        this.dynamicTags.push(label);
      }else{
        this.$message({
                   showClose: true,
                   type: "warning",
                   message: "已存在标签 "+inputValue,
                  });
      }
      // console.log(this.dynamicTags.length+"多长")
      this.inputVisible = false;
      this.inputValue = "";
    },
    
  },
  
};
</script>

<style scoped>
.editblog {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
}
.m-content {
  text-align: center;
  margin-top: 70px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  line-height: 160px;
  background-image: url("../assets/background1.png");
  min-height: calc(100vh - 56px);
}

#particles-js {
  width: 100%;
  height: calc(100% - 100px);
  position: fixed; /* 设置absolute,其他DIV设置为relative,这样这个例子效果就作为背景 */
}
.vueparticles {
  width: 100%;
  height: 100%;
  background-attachment: fixed;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
  position: relative;
}
</style>