<template>
    <div>
        <div v-clickoutside="hideReplyBtn" @click="inputFocus" class="my-reply">
            <el-avatar class="header-img" :size="40" :src="myHeader"></el-avatar>
            <div class="reply-info" >
                <div 
                tabindex="0" 
                contenteditable="true" 
                id="replyInput" 
                spellcheck="false" 
                placeholder="输入评论..." 
                class="reply-input" 
                @focus="showReplyBtn"  
                @input="onDivInput($event)"
                >
                </div>
            </div>
            <div class="reply-btn-box" v-show="btnShow">
                <el-button class="reply-btn" size="medium" @click="sendComment" type="primary">发表评论</el-button>
            </div>
        </div>
        <div v-for="(item,i) in comments" :key="i" class="author-title reply-father">
            <el-avatar class="header-img" :size="40" :src="item.headImg"></el-avatar>
            <div class="author-info">
                <span class="author-name">{{item.username}}</span>
                <span class="author-time">{{item.createdTime}}</span>
            </div>
            <div class="icon-btn">
                <span @click="showReplyInput(i,item.username,item.id)"><i class="iconfont el-icon-s-comment"></i>
                <!-- {{item.commentNum}} -->
                </span>
                <i class="iconfont el-icon-caret-top"></i>
                <!-- {{item.plike}} -->
                <span
                class="el-icon-delete"
                v-if="((item.userId == user_id)||isAdmin)"
                @click="deleteCommentById(item.id)"
              ></span>
            </div>
            <div class="talk-box">
                <p>
                    <span class="reply">{{item.message}}</span>
                </p>
            </div>
            <div class="reply-box">
                <div v-for="(reply,j) in item.reply" :key="j" class="author-title">
                    <el-avatar class="header-img" :size="40" :src="reply.headImg"></el-avatar>
                    <div class="author-info">
                        <span class="author-name">{{reply.username}}</span>
                        <span class="author-time">{{reply.createdTime}}</span>
                    </div>
                    <div class="icon-btn">
                        <span @click="showReplyInput(i,reply.username,reply.id)"><i class="iconfont el-icon-s-comment"></i>
                        <!-- {{reply.commentNum}} -->
                        </span>
                        <i class="iconfont el-icon-caret-top"></i>
                        <!-- {{reply.plike}} -->
                        <span
                class="el-icon-delete"
                v-if="((reply.userId == user_id)||isAdmin)"
                @click="deleteCommentById(reply.id)"
              ></span>
                    </div>
                    <div class="talk-box">
                        <p>
                            <span>回复 {{reply.replyUsername}}:</span>
                            <span class="reply">{{reply.message}}</span>
                        </p>
                    </div>
                    <div class="reply-box">

                    </div>
                </div>
                
            </div>
            <div  v-show="_inputShow(i)" class="my-reply my-comment-reply">
                <el-avatar class="header-img" :size="40" :src="myHeader"></el-avatar>
                <div class="reply-info" >
                    <div tabindex="0" contenteditable="true" spellcheck="false" placeholder="输入评论..."   @input="onDivInput($event)"  class="reply-input reply-comment-input"></div>
                </div>
                <div class=" reply-btn-box">
                    <el-button class="reply-btn" size="medium" @click="sendCommentReply(i,item)" type="primary">发表评论</el-button>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import dateformat from 'dateformat'
const clickoutside = {
    // 初始化指令
    bind(el, binding, vnode) {
    function documentHandler(e) {
    // 这里判断点击的元素是否是本身，是本身，则返回
        if (el.contains(e.target)) {
            return false;
        }
    // 判断指令中是否绑定了函数
        if (binding.expression) {
            // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
            binding.value(e);
        }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.vueClickOutside = documentHandler;
    document.addEventListener('click', documentHandler);
    },
    update() {},
    unbind(el, binding) {
    // 解除事件监听
    document.removeEventListener('click', el.vueClickOutside);
    delete el.vueClickOutside;
  },
};
export default {
    name:'BlogComment',
    props: {
    comments: [],
    article_id:{
        type:Number,
        default:-1,
    }
    },
    created(){
        this.getUserinfo();
        this.checkTheUser();
    },
    data(){
        return{
            btnShow: false,
            isAdmin: false,
            index:'0',
            replyComment:'',
            user_id:'',
            username:'',
            myHeader:'https://www.mzy1203.com/download/image/avatar.png',
            reply_username:'',
            reply_id:'',
          
          
        }
    },
    
    directives: {clickoutside},
    mounted(){
    },
    methods: {
        // 获取登录用户信息
        getUserinfo(){
            const _this=this
            let user=localStorage.getItem("userInfo");// 注意不能获取store中的值，要放到localStorage或者会话储存中
            if(user){
                let userInfo=JSON.parse(user)
                _this.username=userInfo.username;
                _this.user_id=userInfo.id;
            }

        },
        checkTheUser(){
        this.$axios.post('/user/checkTheUser').
        then(res =>{
            this.isAdmin = true
        }).catch(err =>{
            this.isAdmin = false
        })
        },
        // 将list转化成tree
        listToTree(list) {
          let info = list.reduce(
        (map, node) => ((map[node.id] = node), (node.reply = []), map),
         {}
        );
        return list.filter((node) => {
          info[node.replyId] &&
          info[node.replyId].reply.push(node);
        return !node.replyId;
         });
      }, 

        // 验证用户是否登录
        userIsLogin(){
        const _this=this
         let user=localStorage.getItem("userInfo");
         if(user==null){
         this.$confirm('评论操作需要先登录，是否现在跳转到登录页面?', '温馨提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning'
        }).then(() => {
           this.$router.push({path: "/loginandregister"});
        }).catch(() => {
          this.$message({
            type: 'success',
            message: '已经为您取消跳转到登录页面'
          });          
        });
        return "no"   
      }
       return "yes"   
    },
        
        inputFocus(){
            let replyInput = document.getElementById('replyInput');
            replyInput.style.padding= "8px 8px"
            replyInput.style.border ="2px solid blue"
            replyInput.focus()
        },  
        showReplyBtn(){
            this.btnShow = true
        },
        hideReplyBtn(){
            this.btnShow = false
            let replyInput = document.getElementById('replyInput');
            replyInput.style.padding= "10px"
            replyInput.style.border ="none"
        },
        showReplyInput(i,username,id){
            // eslint-disable-next-line vue/no-mutating-props
            this.comments[this.index].inputShow = false
            this.index =i
            // eslint-disable-next-line vue/no-mutating-props
            this.comments[i].inputShow = true
            this.reply_username = username
            this.reply_id = id
            
        },
        _inputShow(i){
            // return this.comments[i].inputShow 
            return true
        },
        

        deleteCommentById(id) {
         this.$axios.delete(`/message/delete/${id}`)
         .then(res => {
            //  console.log(res)
             if (res.data.status==200) {
                   this.$message({
                   showClose: true,
                   type: "success",
                   message: res.data.data,
                  });
                  }
         })
         .catch(err => {
             console.error(err); 
             
         })
    },
        sendComment(){
            if( this.userIsLogin()=="no") return;
            if(!this.replyComment){
                 this.$message({
                    showClose: true,
                    type:'warning',
                    message:'评论不能为空'
                })
            }else{
                let object ={}
                let input =  document.getElementById('replyInput')
                let timeNow =dateformat(new Date(),"yyyy-mm-dd HH:mm:ss");
                object.username= this.username
                object.userId=this.user_id
                object.message =this.replyComment
                object.replyId=0
                object.parentId=0
                object.headImg = this.myHeader
                // object.commentNum = 0
                // object.plike = 0
                // object.createdtime=timeNow
                object.articleId=this.article_id
                // console.log(object.article_id+" 文章id log输出")
                this.$axios.post('/message/save',object)
                .then(res => {
                    // console.log(res)

                    if (res.status==200) {
                   this.$message({
                   showClose: true,
                   type: "success",
                   message: "评论已成功发送,等待审核",
                  });
                  }
        

                })
                .catch(err => {
                    console.error(err); 
                })
                // // eslint-disable-next-line vue/no-mutating-props
                // this.comments.push(object)
                // this.replyComment = ''
                // input.innerHTML = '';

            }
        },
        sendCommentReply(i,item){
            if( this.userIsLogin()=="no") return;
            if(!this.replyComment){
                 this.$message({
                    showClose: true,
                    type:'warning',
                    message:'评论不能为空'
                })
            }else{
                let object ={}
                let timeNow =dateformat(new Date(),"yyyy-mm-dd HH:mm:ss");
                object.username= this.username
                object.userId=this.user_id
                object.message =this.replyComment
                object.replyId=item.id
                object.replyUsername=item.username
                object.parentId=item.id
                object.headImg = this.myHeader
                // object.commentNum = 0
                // object.like = 0
                object.createdtime=timeNow
                object.articleId=this.article_id
                // console.log(item.replyUsername+"输出")
                // console.log(object.replyUsername+"输出")
                this.$axios.post('/message/save',object)
                .then(res => {
                    // console.log(res)
                    if (res.status==200) {
                   this.$message({
                   showClose: true,
                   type: "success",
                   message: "回复已成功发送,等待审核",
                  });
                  }
                })
                .catch(err => {
                    console.error(err); 
                })
                // // eslint-disable-next-line vue/no-mutating-props
                // this.comments[i].reply.push(object)
                // this.replyComment = ''
                // document.getElementsByClassName("reply-comment-input")[i].innerHTML = ""
            }
        },
        onDivInput: function(e) {
            this.replyComment = e.target.innerHTML;
        },
        dateStr(date){
            //获取js 时间戳
            var time=new Date().getTime();
            //去掉 js 时间戳后三位，与php 时间戳保持一致
            time=parseInt((time-date)/1000);
            //存储转换值 
            var s;
            if(time<60*10){//十分钟内
                return '刚刚';
            }else if((time<60*60)&&(time>=60*10)){
                //超过十分钟少于1小时
                s = Math.floor(time/60);
                return  s+"分钟前";
            }else if((time<60*60*24)&&(time>=60*60)){ 
                //超过1小时少于24小时
                s = Math.floor(time/60/60);
                return  s+"小时前";
            }else if((time<60*60*24*30)&&(time>=60*60*24)){ 
                //超过1天少于30天内
                s = Math.floor(time/60/60/24);
                return s+"天前";
            }else{ 
                //超过30天ddd
                let date= new Date(parseInt(date));
                return date.getFullYear()+"/"+(date.getMonth()+1)+"/"+date.getDate();
            }
        }
    },    
}
</script>


<style lang="stylus" scoped>
.my-reply
    padding 10px
    background-color #fafbfc
    
    .header-img
        display inline-block
        vertical-align top
    .reply-info    
        display inline-block
        margin-left 5px
        width 90%
        @media screen and (max-width:1200px) {
            width 80%
        }
        .reply-input
            min-height 20px
            line-height 22px
            padding 10px 10px
            color #ccc
            background-color #fff
            border-radius 5px
            &:empty:before
                content attr(placeholder)
            &:focus:before
                content none
            &:focus
                padding 8px 8px
                border 2px solid blue
                box-shadow none
                outline none
    .reply-btn-box
        height 25px
        margin 10px 0
        .reply-btn
            position relative
            float right
            margin-right 15px
.my-comment-reply
    margin-left 50px
    .reply-input
        width flex
.author-title:not(:last-child)
    border-bottom: 1px solid rgba(178,186,194,.3)
.author-title
    padding 10px
    .header-img
        display inline-block
        vertical-align top
    .author-info
        display inline-block
        margin-left 5px
        width 60%
        height 40px
        line-height 20px
        >span 
            display block
            cursor pointer
            overflow hidden
            white-space nowrap
            text-overflow ellipsis
        .author-name
            color #000
            font-size 18px
            font-weight bold
        .author-time
            font-size 14px
    .icon-btn
        width 30%
        padding 0 !important 
        float right
        @media screen and (max-width : 1200px){
            width 20%
            padding 7px
        }
        >span 
            cursor pointer
        .iconfont 
            margin 0 5px
    .talk-box
        margin 0 50px
        >p
           margin 0
        .reply
            font-size 16px
            color #000
    .reply-box
        margin 10px 0 0 50px
        background-color #efefef
</style>

