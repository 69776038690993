<template>
  <div  class="bgBackground" :style="allbackground">
    <vue-particles color="#dedede" class="vueparticles">
    </vue-particles> 
        <blog-header>
        </blog-header>
      
    <div class="header_meta wrapper fn__flex">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="用户名" prop="username" style="font-size:24px">
            <el-input v-model="ruleForm.username"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" style="font-size:24px">
            <el-input show-password type="password" v-model="ruleForm.password"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
            <el-button type="success" @click="register()">现在注册</el-button>
          </el-form-item>
        </el-form>
</div>
     


    <blog-footer class="foot">
    </blog-footer>

  </div>
</template>

<script>
import BlogHeader from '@/components/BlogHeader.vue';
import BlogFooter from '@/components/BlogFooter.vue';
export default {
  components: { BlogHeader, BlogFooter },
    name:"LoginAndRegister",
    data() {
      return {
        ruleForm: {
          username: '',
          password: ''
        },
        allbackground:{
          backgroundImage:'url('+ require('../assets/background1.png') + ')',
          backgroundRepeat:'no-repeat',
          backgroundSize:'cover',
          backgroundPosition:'center top'
        },
        rules: {
          username: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
            { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'change' }
          ]
        }
      };
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const _this = this
            const datas=this.$qs.stringify(this.ruleForm)
            this.$axios.post('/login', datas).then(res => {
              localStorage.setItem("user",res.data.user)
              // console.log(res.data)
              let jwt=res.data.token
              const userInfo = res.data.user

              // 把数据共享出去
              _this.$store.commit("SET_TOKEN", jwt)
              _this.$store.commit("SET_USERINFO", userInfo)
              // 获取
              // console.log("+++++++++++++++++++++++")
              // console.log(_this.$store.getters.getUser)
              // console.log(_this.$store.getters.getToken)
              // let user=localStorage.getItem("userInfo")
              // if(user){
              //   console.log("能正确输出")
              // }
              // console.log(localStorage.getItem("userInfo"))
              // console.log(user)
              _this.$router.push("/blogs")
              
              
            })
            .catch(err => {
                console.error(err); 
                if(err.response.status==401){
                  this.$message({
                type: 'warning',
                 message: err
                }); 
                _this.$router.push("/loginandregister")
                }

            })

          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      register(){
        this.$notify({
          title: '注意',
          message: '注册需验证并收集相关信息,仍可通过邮箱来表达您的疑惑',
          duration: 0
        });
        this.$router.push("/register")
      }
    }
}
</script>

<style scoped >
.header__meta {
    margin-top: 70px
}
.wrapper {
    max-width: 1203px;
    padding: 0 10px;
    margin: 0 auto;
    min-height: calc(100vh - 56px)
}
.fn__flex {
    display: flex
}
.foot{
  margin-bottom: 0px;
  position: relative;;
}
  .demo-ruleForm {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 200px;
  }
  .bgBackground{
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  top: 0;
  left: 0;
  z-index:-2;
}
   #particles-js{
        width: 100%;
        height: calc(100% - 100px);
        position: fixed;   /* 设置absolute,其他DIV设置为relative,这样这个例子效果就作为背景 */
        
    }
  .vueparticles{
    width: 100%;
    height: 100%;
   background-attachment: fixed;
  }
</style>