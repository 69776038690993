<template >
  <div class="bgBackground" :style="allbackground">
    <vue-particles color="#dedede" class="vueparticles">
    </vue-particles> 
    <blog-header></blog-header>
      <div  class="wrapper fn__flex header__meta">
      <div class="main">
        <div class="block">
      <el-timeline >

        <el-timeline-item :label="true" :timestamp="article.createTime" :key='article.id' placement="top" v-for="article in articles">
          <el-card class="card">
            <h4>
              <router-link :to="{name: 'BlogsDetail', params: {articleId: article.id}}">
                {{article.title}}
              </router-link>
            </h4>
            <p>{{article.description}}</p>



          </el-card>
        </el-timeline-item>

      </el-timeline>

      <el-pagination class="mpage"
                     background
                     layout="prev, pager, next"
                     :current-page="currentPage"
                     :page-size="pageSize"
                     :total="total"
                     :page-count="pagecount"
                     @current-change=page
                     style="position:relative">
      </el-pagination>

    </div>
      </div>

      <aside class="side" >
    <music-player></music-player>
    <blog-label style="position:relative"></blog-label>
    <article-star style="position:relative"></article-star>
    </aside>
      </div>

      <blog-footer>
      </blog-footer>
    

    
  </div>
</template>

<script>
import BlogHeader from '@/components/BlogHeader.vue'
import MusicPlayer from '@/components/MusicPlayer.vue'
import BlogFooter from '@/components/BlogFooter.vue'
import BlogLabel from '@/components/BlogLabel.vue'
import ArticleStar from '@/components/ArticleStar.vue'



export default {
    name:"BlogsView.vue",
    components:{BlogHeader,MusicPlayer, BlogFooter, BlogLabel, ArticleStar},
     data() {
      return {
        articles: {},
        currentPage: 1,
        total: 0,
        pageSize: 0,
        pagecount: 0,
        allbackground:{
          backgroundImage:'url('+ require('../assets/background1.png') + ')',
          backgroundRepeat:'no-repeat',
          backgroundSize:'cover',
          backgroundPosition:'center top'
        },
        value: new Date(),
        dynamicTags: [],
      }
    },
    methods: {
      page(currentPage) {
        const _this = this
        _this.$axios.get("/article?currentPage=" + currentPage).then(res => {
          // console.log(res)
          // console.log(res.data)
          // console.log("================")
          // console.log(res.data.data.total)
          // console.log(res.data.data.pageSize)
          // console.log(res.data.data.pageCount)
          // console.log("================")
          _this.articles = JSON.parse(JSON.stringify(res.data.data.json))
          _this.currentPage =res.data.data.current
          _this.total = parseInt(res.data.data.total) 
          _this.pageSize = parseInt(res.data.data.pageSize)
          _this.pagecount= parseInt(res.data.data.pageCount)
        
        })
      },
      getAllTags(){
        const _this=this
        this.$axios.get('/label/allLabel')
        .then(res => {
          // console.log(res)
          _this.dynamicTags=res.data.data
          _this.dynamicTags=JSON.parse(JSON.stringify(this.dynamicTags))
        })
        .catch(err => {
          console.error(err); 
        })


      }
    },
    created() {
      this.page(1)
    }
}
</script>


<style scoped >
.el-timeline {
    margin-left: -30px;
}
.mpage{
  text-align: center;
}
.el-tag + .el-tag {
    margin-left: 10px;
  }
.wrapper {
    max-width: 1203px;
    padding: 0 10px;
    margin: 0 auto
}
.fn__flex {
    display: flex
}
.main {
    flex: 1 1 66.6%;
    max-width: 66.6%;
    flex-shrink: 0
}

.side {
    flex: 1 1 33.4%;
    margin-left: 24px;
    margin-top: 18px;
}

.header__meta {
    margin-top: 70px
}
.card{
  background-color: rgb(233,233,232);
  text-align: center;
}
.bgBackground{
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  top: 0;
  left: 0;
  z-index:-2;
}

 #particles-js{
        width: 100%;
        height: calc(100% - 100px);
        position: fixed;   /* 设置absolute,其他DIV设置为relative,这样这个例子效果就作为背景 */
        
    }
.vueparticles{
  width: 100%;
  height: 100%;
  background-attachment: fixed;
}

</style>
