<template>
  <div>
    <footer class="footer">
        © 2022 马大侠
        <a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2021014264号</a>
      </footer >
  </div>
</template>

<script>
export default {
    name:"BlogFooter"
}
</script>

<style scoped>

.footer {
    background-color: rgba(255, 255, 255, .6);
    padding: 20px 0;
    font-size: 12px;
    color: #999;
    text-align: center;
}

</style>