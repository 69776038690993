<template>
  <div class="bgBackground" :style="allbackground">
    <vue-particles color="#dedede" class="vueparticles">
    </vue-particles> 
    <blog-header>
    </blog-header>

    <div class="mblog fn__flex header__meta" >
      <div class="main">
      <h2 style="text-align: center;"> {{ article.title }}</h2>
      <el-link icon="el-icon-edit" v-if="ownBlog" >
        <router-link :to="{name: 'BlogEdit', params: {articleId:this.articleid}}" >
        编辑
        </router-link>
      </el-link>
      <el-divider></el-divider>
      <!-- <div class="markdown-body" v-html="article.content"></div> -->
      <div class="mdbody">
        <markdown-it-vue style="position:relative" class="md-body" :content="article.content"/>
      </div>
      <div class="battle">
       <!--动画引入animate.css库,icon引入font-awesome图标库  -->
  <vue-star animate="animated bounceIn" color="#F05654" class="star">
    <i slot="icon" class="fa fa-heart" @click="articlelike(articleid)">{{article.like}}</i>
  </vue-star>
    <el-tag 
      v-for="(label,i) in articleLabel"
      :key="i">
      {{label.labelName}}
    </el-tag>
    
    </div>


  <b-log-comment :comments="comments" :article_id="articleid" class="comment" @click1="flushpage()">
    </b-log-comment>
      </div>
    <aside class="side">
      <music-player>
      </music-player>
      <blog-label style="position:relative">
      </blog-label>
      <article-star style="position:relative">
      </article-star>

    </aside>

    </div>

  <blog-footer>
  </blog-footer>
  </div>
</template>

<script>
// import 'github-markdown-css'
import MarkdownItVue from 'markdown-it-vue'
import 'markdown-it-vue/dist/markdown-it-vue.css'
import BlogHeader from '../components/BlogHeader.vue'
import BlogFooter from '../components/BlogFooter.vue'
import MusicPlayer from '../components/MusicPlayer.vue'
import VueStar from 'vue-star'
import 'font-awesome/css/font-awesome.min.css'
import BlogLabel from '@/components/BlogLabel.vue'
import ArticleStar from '@/components/ArticleStar.vue'
import BLogComment from '@/components/BLogComment.vue'
export default {
  components: { BlogHeader, BlogFooter,MusicPlayer,MarkdownItVue,VueStar, BlogLabel, ArticleStar, BLogComment},
    name:"BlogDetail",
    inject:['reload'],
    data() {
      return {
        comments:[],
        articleLabel:[],
        username:'',
        user_id:'',
        article: {
          id: "",
          title: "",
          content: '',
          like:""
        },allbackground:{
          backgroundImage:'url('+ require('../assets/background1.png') + ')',
          backgroundRepeat:'no-repeat',
          backgroundSize:'cover',
          backgroundPosition:'center top'
        },
        ownBlog: false,
        articleid:'',
        likedid:false,
      }
    },
    created() {
      this.getUserinfo()
      this.ownBlog = (this.user_id === 1)
      const articleId = this.$route.params.articleId
      this.articleid=parseInt(articleId) 
      // console.log(this.articleid+"+++++++++++_______________")
      // console.log(articleId)
      const _this = this
      this.$axios.get('/article/' + articleId).then(res => { 
        const article = res.data.data
        _this.article.id = res.data.data.articleId
        // console.log(this.article.id)
        _this.article.title = res.data.data.title
        _this.article.content = res.data.data.content
        _this.article.like=res.data.data.likes
        // let MardownIt = require('markdown-it')
        // let md = new MardownIt()
        // let result = md.render(_this.article.content)
        // _this.article.content = result
        
      })
      this.$axios.get('/label/article?article_id='+articleId)
      .then(res => {
        // console.log(res)
        _this.articleLabel=res.data.data
        _this.articleLabel=JSON.parse(JSON.stringify(this.articleLabel))
        
      })
      .catch(err => {
        console.error(err); 
      })
      this.getComments();
      
    },
    methods: {
      flushpage(){
          this.reload()
          console.log('刷新页面')
      },
      // 获取登录用户信息
        getUserinfo(){
            const _this=this
            let user=localStorage.getItem("userInfo");// 注意不能获取store中的值，要放到localStorage或者会话储存中
            if(user){
                let userInfo=JSON.parse(user)
                _this.username=userInfo.username;
                _this.user_id=userInfo.id;
                // console.log(_this.user_id+"000000000000000000")
            }

        },
         // 验证用户是否登录
        userIsLogin(){
        const _this=this
         let user=localStorage.getItem("userInfo");
         if(user==null){
         this.$confirm('点赞操作需要先登录，是否现在跳转到登录页面?', '温馨提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning'
        }).then(() => {
           this.$router.push({path: "/loginandregister"});
        }).catch(() => {
          this.$message({
            type: 'success',
            message: '已经为您取消跳转到登录页面'
          });          
        });
        return "no"   
      }
       return "yes"   
    },

        articlelike(article_id){
          if( this.userIsLogin()=="no") return;
          let user_id=this.user_id
          this.$axios.get("/article/like?user_id="+user_id+"&article_id="+article_id)
          .then(res => {
            console.log("+++++++++++++++++++++++")
            // console.log(res)
            console.log("+++++++++++++++++++++++")
            if(res.data.data<=0){
              this.likedid=true
              this.$axios.post("/article/like?user_id="+user_id+"&article_id="+article_id)
             .then(res => {
              // console.log(res)
              if (res.status==200) {
                   this.$message({
                   showClose: true,
                   type: "success",
                   message: "眼光不错哦",
                  });
                  }
                  this.article.like=this.article.like+1
            })
            .catch(err => {
              console.error(err); 
            })
            }else{
              this.likedid=false
              this.$message({
                   showClose: true,
                   type: "warning",
                   message: "已点过赞了",
                  });
            }
            
          })
          .catch(err => {
            console.error(err); 
          })
          
        },

      getComments(){
           let article_id=this.$route.params.articleId
            this.$axios.get("/message/articleComment?article_id="+article_id)
            .then(res => {
                // console.log(res)
                this.comments=res.data.data; 
                this.comments=JSON.parse(JSON.stringify(this.comments)) 
                this.comments=this.listToTree(this.comments)
                // console.log("++++++++++++++++++++++")
                // console.log(this.comments)
            })
            .catch(err => {
                console.error(err); 
            })
        },
        // 将list转化成tree
        listToTree(list) {
          let info = list.reduce(
        (map, node) => ((map[node.id] = node), (node.reply = []), map),
         {}
        );
        return list.filter((node) => {
          info[node.parentId] &&
          info[node.parentId].reply.push(node);
        return !node.parentId;
         });
      }, 
    },
}
</script>

<style scoped>
.comment{
  position: relative;
}
  .mblog {
    max-width: 1203px;
    padding: 0 10px;
    margin: 0 auto

  }
.fn__flex {
    display: flex
}
.main {
    flex: 1 1 66.6%;
    max-width: 66.6%;
    flex-shrink: 0
}
.mdbody{
    background-color: rgba(255, 255, 255, .6);
}

.side {
    flex: 1 1 33.4%;
    margin-left: 24px
}

.battle{
  width: 100%;
  height: 100px;
  display: flex
}
.star{
  float: left;
  position: relative;
}

.el-tag {
    height: 32px;
    padding: 0 10px;
    line-height: 30px;
    font-size: 15px;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    box-sizing: border-box;
    white-space: nowrap;
    margin-top: 34px;
    margin-left: 5px;
}

.header__meta {
    margin-top: 70px
}
.bgBackground{
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  top: 0;
  left: 0;
  z-index:-2;
}
 #particles-js{
        width: 100%;
        height: calc(100% - 100px);
        position: fixed;   /* 设置absolute,其他DIV设置为relative,这样这个例子效果就作为背景 */
        
    }
.vueparticles{
  width: 100%;
  height: 100%;
  background-attachment: fixed;
}
</style>
