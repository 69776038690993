<template>
  <div class="bgBackground" :style="allbackground">
      <vue-particles color="#dedede" class="vueparticles">
    </vue-particles> 
     <blog-header></blog-header>
         <div  class="wrapper fn__flex header__meta">
             <div class="main">
                 <b-log-comment :comments="comments" @click1="flushpage()" style="position:relative">
                 </b-log-comment>
             </div>

        <aside class="side" >
    <music-player></music-player>
    <blog-label style="position:relative"></blog-label>
    <article-star style="position:relative"></article-star>
    </aside>
         </div>
         <blog-footer></blog-footer>
  </div>
</template>

<script>
import BlogHeader from '@/components/BlogHeader.vue'
import MusicPlayer from '@/components/MusicPlayer.vue'
import BlogLabel from '@/components/BlogLabel.vue'
import ArticleStar from '@/components/ArticleStar.vue'
import BlogFooter from '@/components/BlogFooter.vue'
import BLogComment from '@/components/BLogComment.vue'
export default {
    name:"PersonMessage",
    components:{BlogHeader, MusicPlayer, BlogLabel, ArticleStar, BlogFooter, BLogComment},
    inject:['reload'],
    data(){
        return {
            comments:[],
            allbackground:{
          backgroundImage:'url('+ require('../assets/background1.png') + ')',
          backgroundRepeat:'no-repeat',
          backgroundSize:'cover',
          backgroundPosition:'center top'
        },
        }
                
    },
    mounted(){
        
    },
    created(){
        this.getComments();
    },
    methods:{
        flushpage(){
          this.reload()
          console.log('刷新页面')
      },
        getComments(){
            this.$axios.get('/message/comment')
            .then(res => {
                console.log("PPPPPPPPPPPPPPPPPPPP")
                console.log(res)
                console.log("PPPPPPPPPPPPPPPPPPPP")
                this.comments=res.data.data; 
                this.comments=JSON.parse(JSON.stringify(this.comments)) 
                this.comments=this.listToTree(this.comments)
                // console.log("PPPPPPPPPPPPPPPPPPPP")
                console.log(this.comments)
                // console.log("PPPPPPPPPPPPPPPPPPPP")
            })
            .catch(err => {
                console.error(err); 
            })
        },
        // 将list转化成tree
        listToTree(list) {
          let info = list.reduce(
        (map, node) => ((map[node.id] = node), (node.reply = []), map),
         {}
        );
        return list.filter((node) => {
          info[node.parentId] &&
          info[node.parentId].reply.push(node);
        return !node.parentId;
         });
      }, 
    }
}
</script>

<style scoped>
.wrapper {
    max-width: 1203px;
    padding: 0 10px;
    margin: 0 auto
}
.fn__flex {
    display: flex
}
.main {
    flex: 1 1 66.6%;
    max-width: 66.6%;
    flex-shrink: 0
}

.side {
    flex: 1 1 33.4%;
    margin-left: 24px;
    margin-top: 18px;
}

.header__meta {
    margin-top: 70px
}
.bgBackground{
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  top: 0;
  left: 0;
  z-index:-2;
}

 #particles-js{
        width: 100%;
        height: calc(100% - 100px);
        position: fixed;   /* 设置absolute,其他DIV设置为relative,这样这个例子效果就作为背景 */
        
    }
.vueparticles{
  width: 100%;
  height: 100%;
  background-attachment: fixed;
}


</style>