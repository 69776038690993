import { render, staticRenderFns } from "./CommentModeration.vue?vue&type=template&id=705d7cbd&scoped=true&"
import script from "./CommentModeration.vue?vue&type=script&lang=js&"
export * from "./CommentModeration.vue?vue&type=script&lang=js&"
import style0 from "./CommentModeration.vue?vue&type=style&index=0&id=705d7cbd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "705d7cbd",
  null
  
)

export default component.exports